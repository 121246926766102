import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'
import moment from 'moment'
import { ITEM_PER_PAGE } from '../../constants';

function Recherche() {
    let { query } = useParams();

    const [results, setResults] = useState([]);
    const [search, setSearch] = useState(null);
    const [title, setTitle] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        loadData()
    }, [search]);

    useEffect(() => {
        loadData()
        // loadDoc()
    }, []);

    const loadData = () => {
        getResource(`search?q=${query}`).then(res => {
            setResults(res.data)
        })
    }

    // const loadDoc = () => {
    //     getResource(`documents?order[annee]=${title ? `&libelle=${title}` : ''}`).then(res => {
    //         setDocuments(res.data['hydra:member'])
    //     })
    // }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Recherche {query}</h2>
                    <div className="bread-crumb">
                        <div className="container text-uppercase">
                            <span><a href="/">ACCUEIL</a>  / </span>
                            <span> Résultats  {query} </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">
                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>
                        <div className="icon" title="Clique pour Recherchez" >
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="list-group">
                        {results.map(act => (
                            act.type == 'Actualité' ? <a key={act.id} href={`/communiques/${act.slug}`} className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="commiqueTitle">{act.titre}</h5>
                                </div>
                            </a> : act.type == 'Inconnu' ?
                                <a href={process.env.REACT_APP_API_BASE_URL + '/' + act.slug.contentUrl} key={act.id} target='_blank' className="list-group-item list-group-item-action">
                                    <div className="d-flex w-100 justify-content-between">
                                        <div>
                                            <h6 className="mb-1">{act.titre}</h6>
                                            {/* <p><small className="text-muted">Publié le {moment(act.date).format('DD MMMM YYYY')}</small></p> */}
                                            {/* <p><small className="text-muted">{act.annee ? act.annee : '-'}</small></p> */}
                                            {/* <p><small className="text-muted">{act.annee ? act.annee : '-'}</small></p> */}
                                        </div>
                                        <div className="w-25">
                                            <div className="document-dbtn">
                                                <a href={process.env.REACT_APP_API_BASE_URL + '/' + act.slug.contentUrl} target='_blank' className="btn btn-sm btn-outline-warning text-dark "
                                                    title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                : ' '))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recherche;